import {ChangeDetectorRef, Component} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {StateService} from "./core/state.service";
import {CommunicationService} from "./core/communication.service";
import {Subject} from "rxjs";

declare global {
  interface Window {
    receiveAudioFromApp: Subject<string>;
    receiveConsentFromApp: Subject<boolean>;
  }
}

window.receiveAudioFromApp = new Subject<string>();
window.receiveConsentFromApp = new Subject<boolean>();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(public readonly cdRef: ChangeDetectorRef) {
    setInterval(() => this.cdRef.detectChanges(), 100);
  }
}
