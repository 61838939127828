import {Injectable} from '@angular/core';
import {PromptResult} from "../capture/capture.service";
import {StateService} from "./state.service";

export interface Theme {
  primaryColor: string;
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(private readonly stateService: StateService) {
  }

  goldbetTenantIds = ['01HWYZP1Q2XBHX0NWAC01G235W', '01HWZBJWR0CVPV7AE3K40SR6YM', '01J2E0TXFVWWHXNEQC7WJ8TYHW']; // goldbet prod, staging and internal-prod-test
  goldbetTheme: Theme = {
    primaryColor: '#FFCC00'
  };

  promptbetTheme: Theme = {
    primaryColor: '#0090ff'
  };

  betterTenantIds = ['01HWZBN63D2RVTWYDW0YY5149T', '01HWYZP9N7NSW250T73BTAPN5K']; // better prod and staging
  betterTheme: Theme = {
    primaryColor: '#1bb14c'
  };


  public injectTheme() {
    console.log('injecting theme', this.stateService.tenantId);
    if (!this.stateService.tenantId) {
      return;
    }

    let theme!: Theme;
    if (this.goldbetTenantIds.includes(this.stateService.tenantId)) {
      this.stateService.theme = 'goldbet';
      this.stateService.clientLogo = 'assets/logos/goldbet.svg';
      theme = this.goldbetTheme;
    } else if (this.betterTenantIds.includes(this.stateService.tenantId)) {
      this.stateService.theme = 'better';
      this.stateService.clientLogo = 'assets/logos/better.png';
      theme = this.betterTheme;
    } else {
      this.stateService.theme = 'promptbet';
      this.stateService.clientLogo = 'assets/logos/promptbet.png';
      theme = this.promptbetTheme;
    }
    const existingStyleEl = document.getElementById('dynamic-theme-style');
    if (existingStyleEl) {
      existingStyleEl.remove();
    }

    const styleEl = document.createElement('style');
    styleEl.id = 'dynamic-theme-style';
    styleEl.textContent = `:root {
      --primary-color: ${theme.primaryColor};
    }
  `;
    console.log('STYLE', this.stateService.theme, styleEl);
    document.head.appendChild(styleEl);
  }
};
