import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AuthInterceptor} from "./app-auth.interceptor";
import {registerLocaleData} from "@angular/common";
import localeEn from '@angular/common/locales/en';
import localeIt from '@angular/common/locales/it';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import {TranslationService} from "./shared/translation/translation.service";
import {StateService} from "./core/state.service";

registerLocaleData(localeEn, 'en');
registerLocaleData(localeIt, 'it');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeFr, 'fr');

export function localeFactory() {
  const lang = navigator.language.split('-')[0];
  return StateService.supportedLanguages.includes(lang) ? lang : 'en';
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }, {
      provide: LOCALE_ID,
      useFactory: localeFactory,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (translationService: TranslationService) => async () => await translationService.init(),
      deps: [TranslationService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
