import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  constructor() {
  }

  close(reason: 'consent-declined' | 'exit' | 'completed') {
    this.emitIframeMessage('close', {reason});
  }

  startRecording() {
    this.emitIframeMessage('mic-start');
  }

  endRecording(status: 'completed' | 'timeout' | 'abort') {
    this.emitIframeMessage('mic-end', {status});
  }

  private emitIframeMessage(type: 'bet' | 'consent' | 'close' | 'mic-start' | 'mic-end' | 'log',
                            data: { [key: string]: any } = {}) {
    const messageData = {
      type,
      ...data
    };
    console.log('emit iframe message', messageData);
    const webkit = (window as any).webkit;
    const android = (window as any).Android;
    if (webkit) {
      webkit.messageHandlers.promptbet.postMessage(messageData);
    } else if (android) {
      android.postMessage(JSON.stringify(messageData));
    }
    window.parent.postMessage(messageData, '*');
  }

  logToApp(message: string) {
    this.emitIframeMessage('log', {message});
  }

  askConsent() {
    this.emitIframeMessage('consent');
  }

  submitBets(bets: any[]) {
    this.emitIframeMessage('bet', {bets});
  }
}
