import {ChangeDetectorRef, Injectable, NgZone} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import {StateService} from "../../core/state.service";

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  translations: Map<string, any> = new Map();

  constructor(private readonly http: HttpClient,
              private readonly stateService: StateService) {
  }

  translateArgs(key: string, ...args: string[]): string {
    try {
      return this.replaceArgsInTranslation(this.getTranslation(key), ...args);
    } catch (e) {
      return key;
    }
  }

  translate(key: string): string {
    try {
      return this.getTranslation(key);
    } catch (e) {
      return key;
    }
  }

  async init() {
    const queryParam = `?v=6.TODO`; // TODO: use package version for invalidating browser caches.
    const enTranslation = await firstValueFrom(this.http.get('/assets/lang/en.json' + queryParam));
    this.translations.set('en', enTranslation);
    const itTranslation = await firstValueFrom(this.http.get('/assets/lang/it.json' + queryParam));
    this.translations.set('it', itTranslation);
    const deTranslation = await firstValueFrom(this.http.get('/assets/lang/de.json' + queryParam));
    this.translations.set('de', deTranslation);
    const esTranslation = await firstValueFrom(this.http.get('/assets/lang/es.json' + queryParam));
    this.translations.set('es', esTranslation);
    const frTranslation = await firstValueFrom(this.http.get('/assets/lang/fr.json' + queryParam));
    this.translations.set('fr', frTranslation);
  }

  private getTranslation(keyString: string, forceEnglish = false): string {
    const keyPath: string[] = keyString.split('.');
    const translatedValue = [
      this.translations.get(forceEnglish || this.stateService.language === 'xx' ? 'en' : this.stateService.language),
      ...keyPath,
    ].reduce((result: string, key: any) => {
      return result[key] ?? (forceEnglish ? keyString : this.getTranslation(keyString, true));
    });

    if (translatedValue === keyString) {
      return [this.translations.get('en'), ...keyPath].reduce(
        (result: string, key: any) => {
          return result[key] ?? keyString;
        }
      );
    }

    return translatedValue;
  }

  private replaceArgsInTranslation(str: string, ...args: string[]) {
    if (args.length === 0 || !str) {
      return '';
    }
    const mapObj: { [key: string]: string } = {};
    args.forEach((arg, idx) => (mapObj['#args' + idx] = arg));
    const regExp: RegExp = new RegExp(Object.keys(mapObj).join('|'), 'gi');
    return str.replace(regExp, matched => mapObj[matched.toLowerCase()]);
  }
}
