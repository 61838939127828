import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {consentGuard} from "./consent.pageguard";

const routes: Routes = [
  {
    path: 'consent',
    loadComponent: () => import('./consent/consent.component').then(mod => mod.ConsentComponent)
  },
  {
    path: 'capture',
    canActivate: [consentGuard],
    loadComponent: () => import('./capture/capture.component').then(mod => mod.CaptureComponent)
  },
  {
    path: 'result',
    loadComponent: () => import('./result/result.component').then(mod => mod.ResultComponent)
  },
  {path: '', redirectTo: 'capture', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
