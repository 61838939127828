import {Injectable} from '@angular/core';
import {Match, PromptResult, Selection} from "../capture/capture.service";

@Injectable({
  providedIn: 'root'
})
export class StateService {

  // include registerLocaleData in app.module when expanding this list
  public static readonly supportedLanguages = ['it', 'en', 'de', 'es', 'fr', 'xx'];
  theme: 'goldbet' | 'better' | 'promptbet' = 'promptbet';
  clientLogo = 'assets/logos/promptbet.png';
  languages = StateService.supportedLanguages;
  language = 'it';
  authToken = '';
  authenticated = false;
  isIOSWebView = this.detectIosWebview();
  isAndroidWebView = this.detectAndroidWebview();
  isNative = this.isIOSWebView || this.isAndroidWebView;

  selection: any[] = [];
  result!: PromptResult;

  selectedMatch?: Match;
  previousPromptResult?: PromptResult;

  tenantId: string | null = null;
  userId: string | null = null;
  testMode = false;

  user: { punterId: string, locked: boolean, termsAccepted: string, tenantId: number } | undefined;


  constructor() {
  }

  public setResult(res: PromptResult, othersLabel: string) {
    const sorter = (s1: Selection, s2: Selection) => {
      const index1 = s1.oddsId ? +s1.oddsId : 998;
      const index2 = s2.oddsId ? +s2.oddsId : 999;
      return index1 - index2;
    };
    res.events.forEach(m => {
      m.selections.sort(sorter);
      m.extendedInfoObj = m?.extendedInfo ? JSON.parse(m.extendedInfo) : null;
      m.selections.forEach(selection => {
        selection.extendedInfoObj = selection?.extendedInfo ? JSON.parse(selection.extendedInfo) : null;
        if (selection.selectionLabel?.toLowerCase() === 'others') {
          selection.selectionLabel = othersLabel;
        }
      });
    });
    res.events.sort((m1, m2) => {
      return new Date(m1.start).getTime() - new Date(m2.start).getTime();
    });
    this.result = res;
  }

  private detectAndroidWebview() {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
    if (/Android/.test(userAgent)) {
      if (userAgent.indexOf('wv') > -1 || userAgent.indexOf('Version/') == -1) {
        console.log('Running in Android WebView');
        return true;
      } else {
        console.log('Running in native Android browser (not WebView)');
      }
    }

    return false;
  }

  private detectIosWebview() {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
    if (/iPhone|iPad|iPod/.test(userAgent) && !(window as any).MSStream) {
      if (!/(Safari)/.test(userAgent)) {
        console.log('Running in iOS WebView');
        return true;
      } else {
        console.log('Running in Safari (not WebView)');
      }
    }
    return false;
  }

  setTestResult() {
    this.setResult( JSON.parse(JSON.stringify(listSample)) as any, 'others');
    //this.setResult( JSON.parse(JSON.stringify(singleListSample)) as any, 'others');
    return;
  }

  setTestResult2() {
    this.setResult( JSON.parse(JSON.stringify(singleSample)) as any, 'others');
    return;
  }

  isSelected(selectionId: number) {
    return this.selection.some(sel => sel.selectionId === selectionId);
  }
}

export const listSample = {
  "id": "cde3e792-3ac3-42f2-88ec-8149f5fc135a",
  "text": "Arsenal, Liverpool",
  "metadata": null,
  "events": [
    {
      "id": "d4481bc3-d83b-4d48-a52c-5f3f912e7bfa",
      "eventId": "11629283",
      "start": "2024-09-14T14:00:00Z",
      "name": "Liverpool - Nottingham Forest",
      "homeTeamToken": null,
      "awayTeamToken": null,
      "tournamentId": "26604",
      "tournamentName": "Premier League",
      "disciplineId": "1",
      "sportId": "1",
      "sportName": "Soccer",
      "categoryId": "3010",
      "categoryName": "Inghilterra",
      "extendedInfo": "{\"AamsId\": \"34371.1962\", \"IsLive\": \"false\", \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": \"13070\", \"MarkMultipla\": null}",
      "selections": [
        {
          "id": "479d6eb1-ffb7-418f-908c-b7709cdf4d98",
          "oddsId": "-1009101186",
          "selectionId": "-1009101186",
          "marketId": "1209412327",
          "marketLabel": "Esito Finale 1X2",
          "selectionLabel": "X",
          "oddsAmerican": "285",
          "odds": 3.85,
          "selected": false,
          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "50c1eb50-0fe8-444b-b52f-6a653981b3b1",
          "oddsId": "-1009100755",
          "selectionId": "-1009100755",
          "marketId": "1209412464",
          "marketLabel": "Under/Over (2.50)",
          "selectionLabel": "Over",
          "oddsAmerican": "-167",
          "odds": 1.6,
          "selected": false,
          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "9b9ef1b3-d998-47ae-a8b8-cdfe79d71032",
          "oddsId": "-1009101185",
          "selectionId": "-1009101185",
          "marketId": "1209412327",
          "marketLabel": "Esito Finale 1X2",
          "selectionLabel": "2",
          "oddsAmerican": "-130",
          "odds": 1.77,
          "selected": false,
          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "c51abe84-fe2b-485a-bf3f-7e1cb052f597",
          "oddsId": "-1009101187",
          "selectionId": "-1009101187",
          "marketId": "1209412327",
          "marketLabel": "Esito Finale 1X2",
          "selectionLabel": "1",
          "oddsAmerican": "310",
          "odds": 4.1,
          "selected": false,
          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "ce36761a-e1bd-406a-aefc-01cae1e907ab",
          "oddsId": "-1009101121",
          "selectionId": "-1009101121",
          "marketId": "1209412339",
          "marketLabel": "Gol/NoGol",
          "selectionLabel": "NoGol",
          "oddsAmerican": "120",
          "odds": 2.2,
          "selected": false,
          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "ef5f4379-306b-47ea-b2a6-ef00b3e14d77",
          "oddsId": "-1009100756",
          "selectionId": "-1009100756",
          "marketId": "1209412464",
          "marketLabel": "Under/Over (2.50)",
          "selectionLabel": "Under",
          "oddsAmerican": "120",
          "odds": 2.2,
          "selected": false,
          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "fbf7ef6b-5e34-44ed-9f02-11d4464ba12f",
          "oddsId": "-1009101122",
          "selectionId": "-1009101122",
          "marketId": "1209412339",
          "marketLabel": "Gol/NoGol",
          "selectionLabel": "Gol",
          "oddsAmerican": "-167",
          "odds": 1.6,
          "selected": false,
          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        }
      ]
    },
    {
      "id": "871701c6-404e-4b4a-a195-bf5cc4cc460d",
      "eventId": "11629280",
      "start": "2024-09-15T13:00:00Z",
      "name": "Tottenham - Arsenal",
      "homeTeamToken": null,
      "awayTeamToken": null,
      "tournamentId": "26604",
      "tournamentName": "Premier League",
      "disciplineId": "1",
      "sportId": "1",
      "sportName": "Soccer",
      "categoryId": "3010",
      "categoryName": "Inghilterra",
      "extendedInfo": "{\"AamsId\": \"34371.1965\", \"IsLive\": \"false\", \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": \"13284\", \"MarkMultipla\": null}",
      "selections": [
        {
          "id": "67ebb681-b845-4f41-a265-5c72e801131a",
          "oddsId": "-1009094405",
          "selectionId": "-1009094405",
          "marketId": "1209414567",
          "marketLabel": "Esito Finale 1X2",
          "selectionLabel": "2",
          "oddsAmerican": "-105",
          "odds": 1.95,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "9858060e-7bc5-46d3-8f4c-93d5e72345d8",
          "oddsId": "-1009094406",
          "selectionId": "-1009094406",
          "marketId": "1209414567",
          "marketLabel": "Esito Finale 1X2",
          "selectionLabel": "X",
          "oddsAmerican": "270",
          "odds": 3.7,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "c0a17a76-7ae1-4be5-bbd0-aee79561d2a9",
          "oddsId": "-1009094407",
          "selectionId": "-1009094407",
          "marketId": "1209414567",
          "marketLabel": "Esito Finale 1X2",
          "selectionLabel": "1",
          "oddsAmerican": "250",
          "odds": 3.5,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        }
      ]
    }
  ],
  "outrightEvents": []
};

export const singleListSample = {
  "id": "cde3e792-3ac3-42f2-88ec-8149f5fc135a",
  "text": "Arsenal, Liverpool",
  "metadata": null,
  "events": [
    {
      "id": "d4481bc3-d83b-4d48-a52c-5f3f912e7bfa",
      "eventId": "11629283",
      "start": "2024-09-14T14:00:00Z",
      "name": "Liverpool - Manchester",
      "homeTeamToken": null,
      "awayTeamToken": null,
      "tournamentId": "26604",
      "tournamentName": "Premier League",
      "disciplineId": "1",
      "sportId": "1",
      "sportName": "Soccer",
      "categoryId": "3010",
      "categoryName": "Inghilterra",
      "extendedInfo": "{\"quickLaunch\": true, \"AamsId\": \"34371.1962\", \"IsLive\": \"false\", \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": \"13070\", \"MarkMultipla\": null}",
      "selections": [
        {
          "id": "479d6eb1-ffb7-418f-908c-b7709cdf4d98",
          "oddsId": "-1009101186",
          "selectionId": "-1009101186",
          "marketId": "1209412327",
          "marketLabel": "Esito Finale 1X2",
          "selectionLabel": "X",
          "oddsAmerican": "285",
          "odds": 1.85,
          "selected": false,
          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "50c1eb50-0fe8-444b-b52f-6a653981b3b1",
          "oddsId": "-1009100755",
          "selectionId": "-1009100755",
          "marketId": "1209412464",
          "marketLabel": "Under/Over (2.50)",
          "selectionLabel": "Over",
          "oddsAmerican": "-167",
          "odds": 0.6,
          "selected": false,
          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "9b9ef1b3-d998-47ae-a8b8-cdfe79d71032",
          "oddsId": "-1009101185",
          "selectionId": "-1009101185",
          "marketId": "1209412327",
          "marketLabel": "Esito Finale 1X2",
          "selectionLabel": "2",
          "oddsAmerican": "-130",
          "odds": 0.77,
          "selected": false,
          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "c51abe84-fe2b-485a-bf3f-7e1cb052f597",
          "oddsId": "-1009101187",
          "selectionId": "-1009101187",
          "marketId": "1209412327",
          "marketLabel": "Esito Finale 1X2",
          "selectionLabel": "1",
          "oddsAmerican": "310",
          "odds": 4.1,
          "selected": false,
          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "ce36761a-e1bd-406a-aefc-01cae1e907ab",
          "oddsId": "-1009101121",
          "selectionId": "-1009101121",
          "marketId": "1209412339",
          "marketLabel": "Gol/NoGol",
          "selectionLabel": "NoGol",
          "oddsAmerican": "120",
          "odds": 2.2,
          "selected": false,
          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "ef5f4379-306b-47ea-b2a6-ef00b3e14d77",
          "oddsId": "-1009100756",
          "selectionId": "-1009100756",
          "marketId": "1209412464",
          "marketLabel": "Under/Over (2.50)",
          "selectionLabel": "Under",
          "oddsAmerican": "120",
          "odds": 234.2,
          "selected": false,
          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "fbf7ef6b-5e34-44ed-9f02-11d4464ba12f",
          "oddsId": "-1009101122",
          "selectionId": "-1009101122",
          "marketId": "1209412339",
          "marketLabel": "Gol/NoGol",
          "selectionLabel": "Gol",
          "oddsAmerican": "-167",
          "odds": 1.6,
          "selected": false,
          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        }
      ]
    },
    {
      "id": "d4481bc3-d83b-4d48-a52c-5f3f912e7bfa",
      "eventId": "11629283",
      "start": "2024-09-15T14:00:00Z",
      "name": "Milan - Liverpool",
      "homeTeamToken": null,
      "awayTeamToken": null,
      "tournamentId": "26604",
      "tournamentName": "Premier League",
      "disciplineId": "1",
      "sportId": "1",
      "sportName": "Soccer",
      "categoryId": "3010",
      "categoryName": "Inghilterra",
      "extendedInfo": "{\"quickLaunch\": true, \"AamsId\": \"34371.1962\", \"IsLive\": \"false\", \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": \"13070\", \"MarkMultipla\": null}",
      "selections": [
        {
          "id": "479d6eb1-ffb7-418f-908c-b7709cdf4d98",
          "oddsId": "-1009101186",
          "selectionId": "-1009101186",
          "marketId": "1209412327",
          "marketLabel": "Esito Finale 1X2",
          "selectionLabel": "X",
          "oddsAmerican": "285",
          "odds": 3.85,
          "selected": false,
          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "50c1eb50-0fe8-444b-b52f-6a653981b3b1",
          "oddsId": "-1009100755",
          "selectionId": "-1009100755",
          "marketId": "1209412464",
          "marketLabel": "Under/Over (2.50)",
          "selectionLabel": "Over",
          "oddsAmerican": "-167",
          "odds": 1.6,
          "selected": false,
          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "9b9ef1b3-d998-47ae-a8b8-cdfe79d71032",
          "oddsId": "-1009101185",
          "selectionId": "-1009101185",
          "marketId": "1209412327",
          "marketLabel": "Esito Finale 1X2",
          "selectionLabel": "2",
          "oddsAmerican": "-130",
          "odds": 1.77,
          "selected": false,
          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "c51abe84-fe2b-485a-bf3f-7e1cb052f597",
          "oddsId": "-1009101187",
          "selectionId": "-1009101187",
          "marketId": "1209412327",
          "marketLabel": "Esito Finale 1X2",
          "selectionLabel": "1",
          "oddsAmerican": "310",
          "odds": 4.1,
          "selected": false,
          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "ce36761a-e1bd-406a-aefc-01cae1e907ab",
          "oddsId": "-1009101121",
          "selectionId": "-1009101121",
          "marketId": "1209412339",
          "marketLabel": "Gol/NoGol",
          "selectionLabel": "NoGol",
          "oddsAmerican": "120",
          "odds": 2.2,
          "selected": false,
          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "ef5f4379-306b-47ea-b2a6-ef00b3e14d77",
          "oddsId": "-1009100756",
          "selectionId": "-1009100756",
          "marketId": "1209412464",
          "marketLabel": "Under/Over (2.50)",
          "selectionLabel": "Under",
          "oddsAmerican": "120",
          "odds": 2.2,
          "selected": false,
          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "fbf7ef6b-5e34-44ed-9f02-11d4464ba12f",
          "oddsId": "-1009101122",
          "selectionId": "-1009101122",
          "marketId": "1209412339",
          "marketLabel": "Gol/NoGol",
          "selectionLabel": "Gol",
          "oddsAmerican": "-167",
          "odds": 1.6,
          "selected": false,
          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        }
      ]
    },
    {
      "id": "d4481bc3-d83b-4d48-a52c-5f3f912e7bfa",
      "eventId": "11629283",
      "start": "2024-09-16T11:00:00Z",
      "name": "Liverpool - Nottingham Forest",
      "homeTeamToken": null,
      "awayTeamToken": null,
      "tournamentId": "26604",
      "tournamentName": "Premier League",
      "disciplineId": "1",
      "sportId": "1",
      "sportName": "Soccer",
      "categoryId": "3010",
      "categoryName": "Inghilterra",
      "extendedInfo": "{\"AamsId\": \"34371.1962\", \"IsLive\": \"false\", \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": \"13070\", \"MarkMultipla\": null}",
      "selections": [
        {
          "id": "479d6eb1-ffb7-418f-908c-b7709cdf4d98",
          "oddsId": "-1009101186",
          "selectionId": "-1009101186",
          "marketId": "1209412327",
          "marketLabel": "Esito Finale 1X2",
          "selectionLabel": "X",
          "oddsAmerican": "285",
          "odds": 234121.85,
          "selected": false,
          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "50c1eb50-0fe8-444b-b52f-6a653981b3b1",
          "oddsId": "-1009100755",
          "selectionId": "-1009100755",
          "marketId": "1209412464",
          "marketLabel": "Under/Over (2.50)",
          "selectionLabel": "Over",
          "oddsAmerican": "-167",
          "odds": 1.6,
          "selected": false,
          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "9b9ef1b3-d998-47ae-a8b8-cdfe79d71032",
          "oddsId": "-1009101185",
          "selectionId": "-1009101185",
          "marketId": "1209412327",
          "marketLabel": "Esito Finale 1X2",
          "selectionLabel": "2",
          "oddsAmerican": "-130",
          "odds": 5.77,
          "selected": false,
          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "c51abe84-fe2b-485a-bf3f-7e1cb052f597",
          "oddsId": "-1009101187",
          "selectionId": "-1009101187",
          "marketId": "1209412327",
          "marketLabel": "Esito Finale 1X2",
          "selectionLabel": "1",
          "oddsAmerican": "310",
          "odds": 4.1,
          "selected": false,
          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "ce36761a-e1bd-406a-aefc-01cae1e907ab",
          "oddsId": "-1009101121",
          "selectionId": "-1009101121",
          "marketId": "1209412339",
          "marketLabel": "Gol/NoGol",
          "selectionLabel": "NoGol",
          "oddsAmerican": "120",
          "odds": 1.2,
          "selected": false,
          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "ef5f4379-306b-47ea-b2a6-ef00b3e14d77",
          "oddsId": "-1009100756",
          "selectionId": "-1009100756",
          "marketId": "1209412464",
          "marketLabel": "Under/Over (2.50)",
          "selectionLabel": "Under",
          "oddsAmerican": "120",
          "odds": 2.2,
          "selected": false,
          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "fbf7ef6b-5e34-44ed-9f02-11d4464ba12f",
          "oddsId": "-1009101122",
          "selectionId": "-1009101122",
          "marketId": "1209412339",
          "marketLabel": "Gol/NoGol",
          "selectionLabel": "Gol",
          "oddsAmerican": "-167",
          "odds": 1.26,
          "selected": false,
          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        }
      ]
    },
    {
      "id": "871701c6-404e-4b4a-a195-bf5cc4cc460d",
      "eventId": "11629280",
      "start": "2024-09-15T13:00:00Z",
      "name": "Tottenham - Arsenal",
      "homeTeamToken": null,
      "awayTeamToken": null,
      "tournamentId": "26604",
      "tournamentName": "Premier League",
      "disciplineId": "1",
      "sportId": "1",
      "sportName": "Soccer",
      "categoryId": "3010",
      "categoryName": "Inghilterra",
      "extendedInfo": "{\"AamsId\": \"34371.1965\", \"IsLive\": \"false\", \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": \"13284\", \"MarkMultipla\": null}",
      "selections": [
        {
          "id": "67ebb681-b845-4f41-a265-5c72e801131a",
          "oddsId": "-1009094405",
          "selectionId": "-1009094405",
          "marketId": "1209414567",
          "marketLabel": "Esito Finale 1X2",
          "selectionLabel": "2",
          "oddsAmerican": "-105",
          "odds": 51.95,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "9858060e-7bc5-46d3-8f4c-93d5e72345d8",
          "oddsId": "-1009094406",
          "selectionId": "-1009094406",
          "marketId": "1209414567",
          "marketLabel": "Esito Finale 1X2",
          "selectionLabel": "X",
          "oddsAmerican": "270",
          "odds": 35.7,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "c0a17a76-7ae1-4be5-bbd0-aee79561d2a9",
          "oddsId": "-1009094407",
          "selectionId": "-1009094407",
          "marketId": "1209414567",
          "marketLabel": "Esito Finale 1X2",
          "selectionLabel": "1",
          "oddsAmerican": "250",
          "odds": 35.5,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        }
      ]
    }
  ],
  "outrightEvents": []
};

export const singleSample = {
  "id": "32c2e300-6c6c-41af-992e-091a7b77c83d",
  "text": "Inter Milan vincerà 2-0",
  "metadata": null,
  "events": [
    {
      "id": "a44614b7-7e94-49cb-8aa2-c0b361c5d281",
      "eventId": "11632772",
      "start": "2024-09-15T18:45:00Z",
      "name": "Monza - Inter",
      "homeTeamToken": null,
      "awayTeamToken": null,
      "tournamentId": "93",
      "tournamentName": "SerieA",
      "disciplineId": "1",
      "sportId": "1",
      "sportName": "Soccer",
      "categoryId": "1577",
      "categoryName": "Italia",
      "extendedInfo": "{\"AamsId\": \"34371.2925\", \"IsLive\": \"false\", \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": \"14098\", \"MarkMultipla\": null}",
      "selections": [
        {
          "id": "67ebb681-b845-4f41-a265-5c72e801131a",
          "oddsId": "-1009094405",
          "selectionId": "-1009094405",
          "marketId": "1209414567",
          "marketLabel": "Esito Finale 1X2",
          "selectionLabel": "2",
          "oddsAmerican": "-105",
          "odds": 1.95,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "9858060e-7bc5-46d3-8f4c-93d5e72345d8",
          "oddsId": "-1009094406",
          "selectionId": "-1009094406",
          "marketId": "1209414567",
          "marketLabel": "Esito Finale 1X2",
          "selectionLabel": "X",
          "oddsAmerican": "270",
          "odds": 3.7,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "c0a17a76-7ae1-4be5-bbd0-aee79561d2a9",
          "oddsId": "-1009094407",
          "selectionId": "-1009094407",
          "marketId": "1209414567",
          "marketLabel": "Esito Finale 1X2",
          "selectionLabel": "1",
          "oddsAmerican": "250",
          "odds": 3.5,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"1\"}"
        },
        {
          "id": "5a27ed2c-eee3-4d28-8868-95b1d46d6c61",
          "oddsId": "-1007892298",
          "selectionId": "-1007892298",
          "marketId": "1209856899",
          "marketLabel": "Risultato Esatto 19 Esiti",
          "selectionLabel": "0-0",
          "oddsAmerican": "1100",
          "odds": 12,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 26 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "27465d04-9aa5-42fc-ae79-ae6fb96147c2",
          "oddsId": "-1007892304",
          "selectionId": "-1007892304",
          "marketId": "1209856899",
          "marketLabel": "Risultato Esatto 19 Esiti",
          "selectionLabel": "0-1",
          "oddsAmerican": "625",
          "odds": 7.25,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 26 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "d4199990-fc62-4fa3-a8e8-74e60eeb5972",
          "oddsId": "-1007892303",
          "selectionId": "-1007892303",
          "marketId": "1209856899",
          "marketLabel": "Risultato Esatto 19 Esiti",
          "selectionLabel": "0-2",
          "oddsAmerican": "575",
          "odds": 6.75,

          "enabled": true,
          "isHighlighted": true,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 26 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "47241ebe-7d4c-4727-8b3f-2908bc6fc19b",
          "oddsId": "-1007892301",
          "selectionId": "-1007892301",
          "marketId": "1209856899",
          "marketLabel": "Risultato Esatto 19 Esiti",
          "selectionLabel": "0-3",
          "oddsAmerican": "850",
          "odds": 9.5,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 26 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "9d8a5b48-01c6-4e0d-b27d-8de616363abf",
          "oddsId": "-1007892310",
          "selectionId": "-1007892310",
          "marketId": "1209856899",
          "marketLabel": "Risultato Esatto 19 Esiti",
          "selectionLabel": "1-0",
          "oddsAmerican": "1400",
          "odds": 15,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 26 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "e8ebfe36-6126-4c32-aa22-2bf5525dcc6a",
          "oddsId": "-1007892297",
          "selectionId": "-1007892297",
          "marketId": "1209856899",
          "marketLabel": "Risultato Esatto 19 Esiti",
          "selectionLabel": "1-1",
          "oddsAmerican": "675",
          "odds": 7.75,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 26 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "f4f005e5-3a58-4949-8819-97794f61c918",
          "oddsId": "-1007892302",
          "selectionId": "-1007892302",
          "marketId": "1209856899",
          "marketLabel": "Risultato Esatto 19 Esiti",
          "selectionLabel": "1-2",
          "oddsAmerican": "750",
          "odds": 8.5,

          "enabled": true,
          "isHighlighted": true,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 26 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "525ade71-74b3-4854-8b35-654215fe6e95",
          "oddsId": "-1007892300",
          "selectionId": "-1007892300",
          "marketId": "1209856899",
          "marketLabel": "Risultato Esatto 19 Esiti",
          "selectionLabel": "1-3",
          "oddsAmerican": "1000",
          "odds": 11,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 26 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "cc75474f-3178-4de3-8e0d-3699470692aa",
          "oddsId": "-1007892309",
          "selectionId": "-1007892309",
          "marketId": "1209856899",
          "marketLabel": "Risultato Esatto 19 Esiti",
          "selectionLabel": "2-0",
          "oddsAmerican": "4200",
          "odds": 43,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 26 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "b8c86ab8-d15d-47ff-a65f-4ca1915e4bd5",
          "oddsId": "-1007892308",
          "selectionId": "-1007892308",
          "marketId": "1209856899",
          "marketLabel": "Risultato Esatto 19 Esiti",
          "selectionLabel": "2-1",
          "oddsAmerican": "1900",
          "odds": 20,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 26 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "984dba77-30df-4537-bbb5-eb18acd24435",
          "oddsId": "-1007892296",
          "selectionId": "-1007892296",
          "marketId": "1209856899",
          "marketLabel": "Risultato Esatto 19 Esiti",
          "selectionLabel": "2-2",
          "oddsAmerican": "1500",
          "odds": 16,

          "enabled": true,
          "isHighlighted": true,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 26 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "36a2fdf2-b5d5-44f1-a07c-958af6e7bb2b",
          "oddsId": "-1007892299",
          "selectionId": "-1007892299",
          "marketId": "1209856899",
          "marketLabel": "Risultato Esatto 19 Esiti",
          "selectionLabel": "2-3",
          "oddsAmerican": "2600",
          "odds": 27,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 26 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "06c65d69-b0e7-4a0a-89f7-3a0f20b0d571",
          "oddsId": "-1007892307",
          "selectionId": "-1007892307",
          "marketId": "1209856899",
          "marketLabel": "Risultato Esatto 19 Esiti",
          "selectionLabel": "3-0",
          "oddsAmerican": "11900",
          "odds": 120,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 26 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "f0354b9a-98f1-45a2-91fa-9674dc89f5af",
          "oddsId": "-1007892306",
          "selectionId": "-1007892306",
          "marketId": "1209856899",
          "marketLabel": "Risultato Esatto 19 Esiti",
          "selectionLabel": "3-1",
          "oddsAmerican": "6800",
          "odds": 69,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 26 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "ab759e10-f56d-4824-905d-b8b42b096056",
          "oddsId": "-1007892305",
          "selectionId": "-1007892305",
          "marketId": "1209856899",
          "marketLabel": "Risultato Esatto 19 Esiti",
          "selectionLabel": "3-2",
          "oddsAmerican": "6300",
          "odds": 64,

          "enabled": true,
          "isHighlighted": true,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 26 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "89d35603-1b5e-40f8-9bf7-13ae798cb5b0",
          "oddsId": "-1007892295",
          "selectionId": "-1007892295",
          "marketId": "1209856899",
          "marketLabel": "Risultato Esatto 19 Esiti",
          "selectionLabel": "3-3",
          "oddsAmerican": "8000",
          "odds": 81,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 26 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "c518678b-b9b8-4467-b51c-d88fef971acc",
          "oddsId": "-1007892292",
          "selectionId": "-1007892292",
          "marketId": "1209856899",
          "marketLabel": "Risultato Esatto 19 Esiti",
          "selectionLabel": "Pareggio Altro Risultato",
          "oddsAmerican": "11900",
          "odds": 120,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 26 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "99aeb55a-7b6f-4e74-9eae-2255084c07f7",
          "oddsId": "-1007892294",
          "selectionId": "-1007892294",
          "marketId": "1209856899",
          "marketLabel": "Risultato Esatto 19 Esiti",
          "selectionLabel": "Vittoria Team1 Altro Risultato",
          "oddsAmerican": "6000",
          "odds": 61,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 26 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "10f81d8c-ce52-40c7-971d-95528c9b469e",
          "oddsId": "-1007892293",
          "selectionId": "-1007892293",
          "marketId": "1209856899",
          "marketLabel": "Risultato Esatto 19 Esiti",
          "selectionLabel": "Vittoria Team2 Altro Risultato",
          "oddsAmerican": "395",
          "odds": 4.95,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 26 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "b267f165-307b-4088-90d8-e30785ece94c",
          "oddsId": "-1007892369",
          "selectionId": "-1007892369",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "0-0",
          "oddsAmerican": "1100",
          "odds": 12,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "a73240a9-81e0-4277-afd7-26201bf8dbed",
          "oddsId": "-1007892389",
          "selectionId": "-1007892389",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "0-1",
          "oddsAmerican": "625",
          "odds": 7.25,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "194bad05-677b-492c-86d4-578627fbd4aa",
          "oddsId": "-1007892388",
          "selectionId": "-1007892388",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "0-2",
          "oddsAmerican": "575",
          "odds": 6.75,

          "enabled": true,
          "isHighlighted": true,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "3d917a2d-ab89-42b6-ad7c-cf8ddba5d713",
          "oddsId": "-1007892387",
          "selectionId": "-1007892387",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "0-3",
          "oddsAmerican": "850",
          "odds": 9.5,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "7bd54c33-0dae-43ec-b2e0-58924cab14e9",
          "oddsId": "-1007892386",
          "selectionId": "-1007892386",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "0-4",
          "oddsAmerican": "1600",
          "odds": 17,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "e8101a1d-df22-4fe3-a105-260fb76251b8",
          "oddsId": "-1007892361",
          "selectionId": "-1007892361",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "0-5",
          "oddsAmerican": "3800",
          "odds": 39,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "e460dfdb-d37c-4eac-b0a8-cf6a7cbf39b3",
          "oddsId": "-1007892350",
          "selectionId": "-1007892350",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "0-6",
          "oddsAmerican": "9900",
          "odds": 100,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "0d41b10d-60fc-4b41-9626-03c4eaa7063d",
          "oddsId": "-1007892340",
          "selectionId": "-1007892340",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "0-7",
          "oddsAmerican": "27400",
          "odds": 275,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "514b835b-7ab9-49f7-8998-f4256d74a6f2",
          "oddsId": "-1007892330",
          "selectionId": "-1007892330",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "0-8",
          "oddsAmerican": "57400",
          "odds": 575,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "7e8ef41d-b56c-4ca9-8585-2896fd32d99c",
          "oddsId": "-1007892322",
          "selectionId": "-1007892322",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "0-9",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "4d9f6fb0-df00-4d78-a080-d5c8c445e039",
          "oddsId": "-1007892393",
          "selectionId": "-1007892393",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "1-0",
          "oddsAmerican": "1400",
          "odds": 15,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "db1cef74-cb48-4407-9145-8abddb53340c",
          "oddsId": "-1007892385",
          "selectionId": "-1007892385",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "1-1",
          "oddsAmerican": "675",
          "odds": 7.75,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "c1dfc41b-acab-4cb7-8591-42d9e7a2531b",
          "oddsId": "-1007892378",
          "selectionId": "-1007892378",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "1-2",
          "oddsAmerican": "750",
          "odds": 8.5,

          "enabled": true,
          "isHighlighted": true,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "68eb24a2-17aa-4951-83d5-cec85e6f8b7a",
          "oddsId": "-1007892377",
          "selectionId": "-1007892377",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "1-3",
          "oddsAmerican": "1000",
          "odds": 11,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "1bb1130d-0895-44eb-a90d-4a51d90059e0",
          "oddsId": "-1007892376",
          "selectionId": "-1007892376",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "1-4",
          "oddsAmerican": "1900",
          "odds": 20,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "d52674b3-d9ee-4a4e-94e8-02ddfe07611d",
          "oddsId": "-1007892360",
          "selectionId": "-1007892360",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "1-5",
          "oddsAmerican": "4600",
          "odds": 47,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "4f6b8b1d-a8ea-4d47-93fc-933936c59288",
          "oddsId": "-1007892349",
          "selectionId": "-1007892349",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "1-6",
          "oddsAmerican": "11900",
          "odds": 120,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "afbc644f-7e00-4536-a90d-44a19fe1b06d",
          "oddsId": "-1007892339",
          "selectionId": "-1007892339",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "1-7",
          "oddsAmerican": "29900",
          "odds": 300,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "874e30e4-4c47-4cf1-a1e1-f37044a477e1",
          "oddsId": "-1007892329",
          "selectionId": "-1007892329",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "1-8",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "83f5402a-daa6-4edb-b78e-44090783705d",
          "oddsId": "-1007892321",
          "selectionId": "-1007892321",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "1-9",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "e3711137-a6be-45cb-aaf0-48e89c4bbbe5",
          "oddsId": "-1007892392",
          "selectionId": "-1007892392",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "2-0",
          "oddsAmerican": "4200",
          "odds": 43,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "79ee297b-bcd6-458e-b599-55b067f212dc",
          "oddsId": "-1007892381",
          "selectionId": "-1007892381",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "2-1",
          "oddsAmerican": "1900",
          "odds": 20,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "deb7e240-bc75-48de-9249-1e79b3eecbf6",
          "oddsId": "-1007892384",
          "selectionId": "-1007892384",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "2-2",
          "oddsAmerican": "1500",
          "odds": 16,

          "enabled": true,
          "isHighlighted": true,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "4fda285c-52ba-4e6a-ac0e-45c8bf56f789",
          "oddsId": "-1007892373",
          "selectionId": "-1007892373",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "2-3",
          "oddsAmerican": "2600",
          "odds": 27,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "b6839cbe-3078-4b95-a02b-aadfebd0e860",
          "oddsId": "-1007892372",
          "selectionId": "-1007892372",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "2-4",
          "oddsAmerican": "4600",
          "odds": 47,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "62e54620-3f52-4896-9ebe-9ea2295560df",
          "oddsId": "-1007892359",
          "selectionId": "-1007892359",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "2-5",
          "oddsAmerican": "10900",
          "odds": 110,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "ce64d473-025b-440b-9b9c-fb981848a57f",
          "oddsId": "-1007892348",
          "selectionId": "-1007892348",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "2-6",
          "oddsAmerican": "24900",
          "odds": 250,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "7795ecd0-8908-43b2-819f-7dbeb658a8c0",
          "oddsId": "-1007892338",
          "selectionId": "-1007892338",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "2-7",
          "oddsAmerican": "52400",
          "odds": 525,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "158e4dc4-0975-41dd-9787-9051b824fea3",
          "oddsId": "-1007892328",
          "selectionId": "-1007892328",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "2-8",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "cdfda9d2-b52f-40d5-bdd3-ead714675b24",
          "oddsId": "-1007892320",
          "selectionId": "-1007892320",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "2-9",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "9011564d-f114-4941-9ced-46cc60bf82dc",
          "oddsId": "-1007892391",
          "selectionId": "-1007892391",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "3-0",
          "oddsAmerican": "11900",
          "odds": 120,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "f0ec6b1f-4251-4d3e-b5f3-242c4212b6ca",
          "oddsId": "-1007892380",
          "selectionId": "-1007892380",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "3-1",
          "oddsAmerican": "6800",
          "odds": 69,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "a279180c-7807-4871-a672-c00a0cbcf7cf",
          "oddsId": "-1007892375",
          "selectionId": "-1007892375",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "3-2",
          "oddsAmerican": "6300",
          "odds": 64,

          "enabled": true,
          "isHighlighted": true,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "a133af1f-5db3-41f1-8aaf-70ee8d9df2f0",
          "oddsId": "-1007892383",
          "selectionId": "-1007892383",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "3-3",
          "oddsAmerican": "8000",
          "odds": 81,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "ada4de1f-4c01-4c05-bee1-015df4a543ed",
          "oddsId": "-1007892370",
          "selectionId": "-1007892370",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "3-4",
          "oddsAmerican": "12900",
          "odds": 130,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "c8d45460-2d4e-4190-b3d2-d54db71c9577",
          "oddsId": "-1007892358",
          "selectionId": "-1007892358",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "3-5",
          "oddsAmerican": "29900",
          "odds": 300,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "9a252633-e60d-475b-b731-f9f37296278d",
          "oddsId": "-1007892347",
          "selectionId": "-1007892347",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "3-6",
          "oddsAmerican": "54900",
          "odds": 550,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "eecbdcd2-ca07-46b9-8192-c1f3ab563daf",
          "oddsId": "-1007892337",
          "selectionId": "-1007892337",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "3-7",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "6d6b798f-176d-4224-b4db-b021863c9dbc",
          "oddsId": "-1007892327",
          "selectionId": "-1007892327",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "3-8",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "713ac495-4cdb-49f7-8d39-cc270d7c0680",
          "oddsId": "-1007892390",
          "selectionId": "-1007892390",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "4-0",
          "oddsAmerican": "27400",
          "odds": 275,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "fa37253c-701d-419d-8686-ec0218834004",
          "oddsId": "-1007892379",
          "selectionId": "-1007892379",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "4-1",
          "oddsAmerican": "19900",
          "odds": 200,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "34f61487-7588-40f8-b9e3-7696e8480021",
          "oddsId": "-1007892374",
          "selectionId": "-1007892374",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "4-2",
          "oddsAmerican": "18900",
          "odds": 190,

          "enabled": true,
          "isHighlighted": true,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "f3e81760-d41c-4d89-94de-2324e5d6a780",
          "oddsId": "-1007892371",
          "selectionId": "-1007892371",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "4-3",
          "oddsAmerican": "22400",
          "odds": 225,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "a4ea57b2-e4cf-4a98-9937-4bfe773e53f2",
          "oddsId": "-1007892382",
          "selectionId": "-1007892382",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "4-4",
          "oddsAmerican": "27400",
          "odds": 275,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "006420ac-51ee-4a9b-9ef1-d59e6dc58ac6",
          "oddsId": "-1007892357",
          "selectionId": "-1007892357",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "4-5",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "042b0d98-ea50-4c41-bd03-6309acc4d6fa",
          "oddsId": "-1007892346",
          "selectionId": "-1007892346",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "4-6",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "a4f1721d-837d-4daa-98de-38c1bd7e1dd6",
          "oddsId": "-1007892336",
          "selectionId": "-1007892336",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "4-7",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "bc52ba59-fa62-4240-8b09-d50caf7ce2de",
          "oddsId": "-1007892326",
          "selectionId": "-1007892326",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "4-8",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "aff4aea8-b85c-40b3-a70d-18a764958c3f",
          "oddsId": "-1007892368",
          "selectionId": "-1007892368",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "5-0",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "06077939-cc2f-431a-83a1-fc3650f6a91b",
          "oddsId": "-1007892367",
          "selectionId": "-1007892367",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "5-1",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "560f645d-924d-4407-b78c-f94d9474d767",
          "oddsId": "-1007892366",
          "selectionId": "-1007892366",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "5-2",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": true,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "1ee09409-9cbc-4fc9-858c-d719921d0eaf",
          "oddsId": "-1007892365",
          "selectionId": "-1007892365",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "5-3",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "9b8e356a-8510-4851-8c58-13384686a48c",
          "oddsId": "-1007892364",
          "selectionId": "-1007892364",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "5-4",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "e35b107c-e636-4bc8-ac80-cac46dc754d0",
          "oddsId": "-1007892363",
          "selectionId": "-1007892363",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "5-5",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "e9ebb8ff-7e60-4ced-9f47-86e4e7276415",
          "oddsId": "-1007892362",
          "selectionId": "-1007892362",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "5-6",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "4bdcae93-f56e-4c8c-9911-1b5a0cb54a50",
          "oddsId": "-1007892351",
          "selectionId": "-1007892351",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "6-0",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "a3b7bb25-8f8f-4178-b3e9-9cda5baf0798",
          "oddsId": "-1007892352",
          "selectionId": "-1007892352",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "6-1",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "ced0c8cb-6baa-4334-b82d-bf325fd5e372",
          "oddsId": "-1007892353",
          "selectionId": "-1007892353",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "6-2",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": true,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "32e036a4-400f-4bfd-932b-0568a87ed26c",
          "oddsId": "-1007892354",
          "selectionId": "-1007892354",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "6-3",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "5b289aee-d186-493a-b937-59ce2973e6d1",
          "oddsId": "-1007892355",
          "selectionId": "-1007892355",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "6-4",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "55a9f71c-14e1-4d27-919a-33b0dd153d78",
          "oddsId": "-1007892356",
          "selectionId": "-1007892356",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "6-5",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "63ea8bba-62ef-46ab-ab37-60e258959f2d",
          "oddsId": "-1007892345",
          "selectionId": "-1007892345",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "7-0",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "533892bb-e4b6-42db-b5ef-83e9ceaa729f",
          "oddsId": "-1007892344",
          "selectionId": "-1007892344",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "7-1",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "3defeb9e-d90c-4486-8c5c-69dfeb20d654",
          "oddsId": "-1007892343",
          "selectionId": "-1007892343",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "7-2",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": true,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "8e677ed8-b0ae-4d5a-9c17-3196493d12b5",
          "oddsId": "-1007892342",
          "selectionId": "-1007892342",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "7-3",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "af17c41e-59c7-4eb9-889f-9f30beed8407",
          "oddsId": "-1007892341",
          "selectionId": "-1007892341",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "7-4",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "f82656b3-b09b-4902-85b4-d5ad5dd8e739",
          "oddsId": "-1007892335",
          "selectionId": "-1007892335",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "8-0",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "6fbbe44b-7be8-4bb3-9448-ea23449d743b",
          "oddsId": "-1007892334",
          "selectionId": "-1007892334",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "8-1",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "3df326f2-c520-4ca3-9d64-c68b44f2edef",
          "oddsId": "-1007892333",
          "selectionId": "-1007892333",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "8-2",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": true,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "a9e3502d-fc2e-4303-a2bf-9bd4baaa95d4",
          "oddsId": "-1007892332",
          "selectionId": "-1007892332",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "8-3",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "98f03d07-c12e-401d-88c1-92f8a1bfc790",
          "oddsId": "-1007892331",
          "selectionId": "-1007892331",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "8-4",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "2421db52-5482-4ea1-a568-e0918e17a134",
          "oddsId": "-1007892325",
          "selectionId": "-1007892325",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "9-0",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "83153d88-7703-48d9-b527-9adc9982c03c",
          "oddsId": "-1007892324",
          "selectionId": "-1007892324",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "9-1",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "724ea434-16ad-440d-8648-90d61073cc1c",
          "oddsId": "-1007892323",
          "selectionId": "-1007892323",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "9-2",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": true,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "b3dbc292-c29e-4a97-989a-9cfcc5d8f2ce",
          "oddsId": "-1007892319",
          "selectionId": "-1007892319",
          "marketId": "1209856896",
          "marketLabel": "Risultato Esatto 75 Esiti",
          "selectionLabel": "ALTRO",
          "oddsAmerican": "59900",
          "odds": 600,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi l\\u0027esatto risultato della partita al termine dei tempi regolamentari. Vengono proposti 75 risultati, la voce Altro comprende ogni risultato non presente nella lista.\"}"
        },
        {
          "id": "a953c183-4321-423e-8655-3cfe6f2ba780",
          "oddsId": "-1007896791",
          "selectionId": "-1007896791",
          "marketId": "1209855462",
          "marketLabel": "Somma Gol Finale",
          "selectionLabel": "0",
          "oddsAmerican": "1100",
          "odds": 12,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi il numero dei gol segnati durante i tempi regolamentari di una partita\"}"
        },
        {
          "id": "25a929a1-52e8-44e3-844f-db387e8df277",
          "oddsId": "-1007896790",
          "selectionId": "-1007896790",
          "marketId": "1209855462",
          "marketLabel": "Somma Gol Finale",
          "selectionLabel": "1",
          "oddsAmerican": "450",
          "odds": 5.5,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi il numero dei gol segnati durante i tempi regolamentari di una partita\"}"
        },
        {
          "id": "31a9bccf-0dd7-4b8c-aa38-e33bf3444e44",
          "oddsId": "-1007896789",
          "selectionId": "-1007896789",
          "marketId": "1209855462",
          "marketLabel": "Somma Gol Finale",
          "selectionLabel": "2",
          "oddsAmerican": "290",
          "odds": 3.9,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi il numero dei gol segnati durante i tempi regolamentari di una partita\"}"
        },
        {
          "id": "8c7f9008-e1e0-41c9-af8c-f4b0356a722c",
          "oddsId": "-1007896788",
          "selectionId": "-1007896788",
          "marketId": "1209855462",
          "marketLabel": "Somma Gol Finale",
          "selectionLabel": "3",
          "oddsAmerican": "295",
          "odds": 3.95,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi il numero dei gol segnati durante i tempi regolamentari di una partita\"}"
        },
        {
          "id": "9c0c032b-f5fb-4219-95c4-d0f29e5fa81d",
          "oddsId": "-1007896787",
          "selectionId": "-1007896787",
          "marketId": "1209855462",
          "marketLabel": "Somma Gol Finale",
          "selectionLabel": "4",
          "oddsAmerican": "425",
          "odds": 5.25,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi il numero dei gol segnati durante i tempi regolamentari di una partita\"}"
        },
        {
          "id": "a1d7f99d-6cc8-4ea2-93f5-69500fe3947f",
          "oddsId": "-1007896786",
          "selectionId": "-1007896786",
          "marketId": "1209855462",
          "marketLabel": "Somma Gol Finale",
          "selectionLabel": "5+",
          "oddsAmerican": "370",
          "odds": 4.7,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi il numero dei gol segnati durante i tempi regolamentari di una partita\"}"
        },
        {
          "id": "57785cde-b9f9-43f1-b06c-fc5d547059e7",
          "oddsId": "-1007895667",
          "selectionId": "-1007895667",
          "marketId": "1209855815",
          "marketLabel": "Under/Over (3.50)",
          "selectionLabel": "Over",
          "oddsAmerican": "160",
          "odds": 2.6,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi che il numero totale dei gol segnati in partita, entro la fine dei tempi regolamentari, sia inferiore o superiore a 3.5\"}"
        },
        {
          "id": "30c68953-928e-40fc-bb2c-6e6e6a02cb11",
          "oddsId": "-1007895668",
          "selectionId": "-1007895668",
          "marketId": "1209855815",
          "marketLabel": "Under/Over (3.50)",
          "selectionLabel": "Under",
          "oddsAmerican": "-238",
          "odds": 1.42,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi che il numero totale dei gol segnati in partita, entro la fine dei tempi regolamentari, sia inferiore o superiore a 3.5\"}"
        },
        {
          "id": "2dea505b-34d0-41e8-86a7-ff954cb56131",
          "oddsId": "-1007896658",
          "selectionId": "-1007896658",
          "marketId": "1209855493",
          "marketLabel": "Gol/NoGol",
          "selectionLabel": "Gol",
          "oddsAmerican": "-115",
          "odds": 1.87,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi che le squadre segneranno almeno un gol ciascuna (Gol) o nessun gol (No gol) durante la partita entro il termine dei tempi regolamentari.\"}"
        },
        {
          "id": "f86781ce-5579-4c1b-9738-6053cc76b484",
          "oddsId": "-1007896657",
          "selectionId": "-1007896657",
          "marketId": "1209855493",
          "marketLabel": "Gol/NoGol",
          "selectionLabel": "NoGol",
          "oddsAmerican": "-120",
          "odds": 1.83,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi che le squadre segneranno almeno un gol ciascuna (Gol) o nessun gol (No gol) durante la partita entro il termine dei tempi regolamentari.\"}"
        },
        {
          "id": "e791e300-5c68-4105-95e3-e634e078e228",
          "oddsId": "-1007895079",
          "selectionId": "-1007895079",
          "marketId": "1209856019",
          "marketLabel": "Esito Finale 1X2 + U/O (4.50)",
          "selectionLabel": "1 - Over",
          "oddsAmerican": "2300",
          "odds": 24,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"\"}"
        },
        {
          "id": "aad2107e-ac05-4a5a-b608-453c99c412d8",
          "oddsId": "-1007895080",
          "selectionId": "-1007895080",
          "marketId": "1209856019",
          "marketLabel": "Esito Finale 1X2 + U/O (4.50)",
          "selectionLabel": "1 - Under",
          "oddsAmerican": "625",
          "odds": 7.25,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"\"}"
        },
        {
          "id": "6769cfd3-4ac3-452e-9c5c-d6d73ef9630d",
          "oddsId": "-1007895075",
          "selectionId": "-1007895075",
          "marketId": "1209856019",
          "marketLabel": "Esito Finale 1X2 + U/O (4.50)",
          "selectionLabel": "2 - Over",
          "oddsAmerican": "425",
          "odds": 5.25,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"\"}"
        },
        {
          "id": "560fbf73-9550-44e7-90ae-1d90f28d442f",
          "oddsId": "-1007895076",
          "selectionId": "-1007895076",
          "marketId": "1209856019",
          "marketLabel": "Esito Finale 1X2 + U/O (4.50)",
          "selectionLabel": "2 - Under",
          "oddsAmerican": "-143",
          "odds": 1.7,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"\"}"
        },
        {
          "id": "9f7c9c94-378f-4f41-a177-141136452206",
          "oddsId": "-1007895077",
          "selectionId": "-1007895077",
          "marketId": "1209856019",
          "marketLabel": "Esito Finale 1X2 + U/O (4.50)",
          "selectionLabel": "X - Over",
          "oddsAmerican": "3100",
          "odds": 32,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"\"}"
        },
        {
          "id": "ac6f91a6-4ac5-47c3-b946-292acb222f3a",
          "oddsId": "-1007895078",
          "selectionId": "-1007895078",
          "marketId": "1209856019",
          "marketLabel": "Esito Finale 1X2 + U/O (4.50)",
          "selectionLabel": "X - Under",
          "oddsAmerican": "355",
          "odds": 4.55,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"\"}"
        },
        {
          "id": "7ffc8ccb-5801-47f9-86cb-957f450a2009",
          "oddsId": "-1007895099",
          "selectionId": "-1007895099",
          "marketId": "1209856015",
          "marketLabel": "Esito Finale 1X2 + U/O (2.50)",
          "selectionLabel": "1 - Over",
          "oddsAmerican": "900",
          "odds": 10,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi il risultato della partita al termine del tempo regolamentare (1 per vittoria in casa, X per pareggio, 2 per vittoria in trasferta) e che il numero totale dei gol segnati in paritta sia inferiore o superiore a 2.5\"}"
        },
        {
          "id": "89d2d62d-ab10-4f61-84ad-b56b16cf10d3",
          "oddsId": "-1007895100",
          "selectionId": "-1007895100",
          "marketId": "1209856015",
          "marketLabel": "Esito Finale 1X2 + U/O (2.50)",
          "selectionLabel": "1 - Under",
          "oddsAmerican": "1100",
          "odds": 12,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi il risultato della partita al termine del tempo regolamentare (1 per vittoria in casa, X per pareggio, 2 per vittoria in trasferta) e che il numero totale dei gol segnati in paritta sia inferiore o superiore a 2.5\"}"
        },
        {
          "id": "8e879e4a-4d4c-4802-8b02-4ab633f72c45",
          "oddsId": "-1007895095",
          "selectionId": "-1007895095",
          "marketId": "1209856015",
          "marketLabel": "Esito Finale 1X2 + U/O (2.50)",
          "selectionLabel": "2 - Over",
          "oddsAmerican": "-103",
          "odds": 1.97,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi il risultato della partita al termine del tempo regolamentare (1 per vittoria in casa, X per pareggio, 2 per vittoria in trasferta) e che il numero totale dei gol segnati in paritta sia inferiore o superiore a 2.5\"}"
        },
        {
          "id": "6e34a327-2667-495b-a866-b17bdec8b581",
          "oddsId": "-1007895096",
          "selectionId": "-1007895096",
          "marketId": "1209856015",
          "marketLabel": "Esito Finale 1X2 + U/O (2.50)",
          "selectionLabel": "2 - Under",
          "oddsAmerican": "275",
          "odds": 3.75,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi il risultato della partita al termine del tempo regolamentare (1 per vittoria in casa, X per pareggio, 2 per vittoria in trasferta) e che il numero totale dei gol segnati in paritta sia inferiore o superiore a 2.5\"}"
        },
        {
          "id": "df9f917e-8ad5-4359-bbe4-203819d42ac4",
          "oddsId": "-1007895097",
          "selectionId": "-1007895097",
          "marketId": "1209856015",
          "marketLabel": "Esito Finale 1X2 + U/O (2.50)",
          "selectionLabel": "X - Over",
          "oddsAmerican": "1200",
          "odds": 13,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi il risultato della partita al termine del tempo regolamentare (1 per vittoria in casa, X per pareggio, 2 per vittoria in trasferta) e che il numero totale dei gol segnati in paritta sia inferiore o superiore a 2.5\"}"
        },
        {
          "id": "a7b29afc-4c5a-4757-b7ec-7fa2fb279a00",
          "oddsId": "-1007895098",
          "selectionId": "-1007895098",
          "marketId": "1209856015",
          "marketLabel": "Esito Finale 1X2 + U/O (2.50)",
          "selectionLabel": "X - Under",
          "oddsAmerican": "475",
          "odds": 5.75,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi il risultato della partita al termine del tempo regolamentare (1 per vittoria in casa, X per pareggio, 2 per vittoria in trasferta) e che il numero totale dei gol segnati in paritta sia inferiore o superiore a 2.5\"}"
        },
        {
          "id": "12fdfe60-0240-4daa-ab7c-5663948dc581",
          "oddsId": "-1007895115",
          "selectionId": "-1007895115",
          "marketId": "1209856012",
          "marketLabel": "Esito Finale 1X2 + U/O (1.50)",
          "selectionLabel": "1 - Over",
          "oddsAmerican": "800",
          "odds": 9,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"\"}"
        },
        {
          "id": "91c55e0d-9ace-4b32-9c5b-ad81e3460d71",
          "oddsId": "-1007895116",
          "selectionId": "-1007895116",
          "marketId": "1209856012",
          "marketLabel": "Esito Finale 1X2 + U/O (1.50)",
          "selectionLabel": "1 - Under",
          "oddsAmerican": "1400",
          "odds": 15,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"\"}"
        },
        {
          "id": "9590f3c4-8ee8-476d-a464-f55055ff533e",
          "oddsId": "-1007895111",
          "selectionId": "-1007895111",
          "marketId": "1209856012",
          "marketLabel": "Esito Finale 1X2 + U/O (1.50)",
          "selectionLabel": "2 - Over",
          "oddsAmerican": "-172",
          "odds": 1.58,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"\"}"
        },
        {
          "id": "e36f73fb-ee3e-44af-9ff1-a3201f0685ef",
          "oddsId": "-1007895112",
          "selectionId": "-1007895112",
          "marketId": "1209856012",
          "marketLabel": "Esito Finale 1X2 + U/O (1.50)",
          "selectionLabel": "2 - Under",
          "oddsAmerican": "625",
          "odds": 7.25,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"\"}"
        },
        {
          "id": "bca75a4f-4ced-4477-a507-6bbdacaabf7c",
          "oddsId": "-1007895113",
          "selectionId": "-1007895113",
          "marketId": "1209856012",
          "marketLabel": "Esito Finale 1X2 + U/O (1.50)",
          "selectionLabel": "X - Over",
          "oddsAmerican": "475",
          "odds": 5.75,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"\"}"
        },
        {
          "id": "a954ba71-64be-49b4-bacd-3a26359a064f",
          "oddsId": "-1007895114",
          "selectionId": "-1007895114",
          "marketId": "1209856012",
          "marketLabel": "Esito Finale 1X2 + U/O (1.50)",
          "selectionLabel": "X - Under",
          "oddsAmerican": "1100",
          "odds": 12,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"\"}"
        },
        {
          "id": "27d163db-181e-4de5-ab69-73e428db1a61",
          "oddsId": "-1007896219",
          "selectionId": "-1007896219",
          "marketId": "1209855621",
          "marketLabel": "Under/Over Ospite (0.50)",
          "selectionLabel": "Over",
          "oddsAmerican": "-2000",
          "odds": 1.05,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Scommetti se la squadra ospite segnerà meno o più di 0.5 gol\"}"
        },
        {
          "id": "3b1210af-03a5-40c8-94cd-61b114a19bf0",
          "oddsId": "-1007896220",
          "selectionId": "-1007896220",
          "marketId": "1209855621",
          "marketLabel": "Under/Over Ospite (0.50)",
          "selectionLabel": "Under",
          "oddsAmerican": "575",
          "odds": 6.75,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Scommetti se la squadra ospite segnerà meno o più di 0.5 gol\"}"
        },
        {
          "id": "b184c029-d637-4079-bfff-d919a7e88b8f",
          "oddsId": "-1007895324",
          "selectionId": "-1007895324",
          "marketId": "1209855954",
          "marketLabel": "Rete Inviolata Ospite",
          "selectionLabel": "NO",
          "oddsAmerican": "-154",
          "odds": 1.65,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi se la squadra ospite subirà o non subirà almeno un gol durante i tempi regolamentari della partita (Sì: la squadra ospite concluderà la partita con rete inviolata, No: la squadra ospite subirà almeno un gol).\"}"
        },
        {
          "id": "4527001e-8d3b-44a7-822c-50b620f126a5",
          "oddsId": "-1007895325",
          "selectionId": "-1007895325",
          "marketId": "1209855954",
          "marketLabel": "Rete Inviolata Ospite",
          "selectionLabel": "SI",
          "oddsAmerican": "100",
          "odds": 2,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"IsOutright\":\"false\",\"MarkMultipla\":\"1\",\"marketInfoHtml\":\"Prevedi se la squadra ospite subirà o non subirà almeno un gol durante i tempi regolamentari della partita (Sì: la squadra ospite concluderà la partita con rete inviolata, No: la squadra ospite subirà almeno un gol).\"}"
        }
      ]
    }
  ],
  "outrightEvents": [
    {
      "id": "3e3a52de-fc75-4ed8-ae66-d88dea78e3e0",
      "eventId": "11422321",
      "start": "2025-05-31T18:45:00Z",
      "name": "Inter ",
      "homeTeamToken": null,
      "awayTeamToken": null,
      "tournamentId": "235701",
      "tournamentName": "Italia Serie A - Retrocessa Si/No",
      "disciplineId": "1",
      "sportId": "1",
      "sportName": "Soccer",
      "categoryId": "221565",
      "categoryName": "AntepostSpeciali",
      "extendedInfo": "{\"AamsId\": \"35221.124\", \"IsLive\": \"false\", \"IsScorer\": \"false\", \"IsAntepost\": \"true\", \"IsOutright\": \"false\", \"OnlineCode\": \"13534\", \"MarkMultipla\": null}",
      "selections": [
        {
          "id": "41689a76-56d6-49c8-944f-d39d3a837896",
          "oddsId": "-1091315990",
          "selectionId": "-1091315990",
          "marketId": "1178942664",
          "marketLabel": "Retrocessa Si/No",
          "selectionLabel": "Si",
          "oddsAmerican": "99900",
          "odds": 1000,

          "enabled": true,
          "isHighlighted": false,
          "isOutRight": false,
          "extendedInfo": "{\"AamsId\": null, \"IsLive\": null, \"IsScorer\": null, \"IsAntepost\": null, \"IsOutright\": \"false\", \"OnlineCode\": null, \"MarkMultipla\": \"0\"}"
        }
      ]
    }
  ]
};
