import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {StateService} from "./core/state.service";

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(private state: StateService) {
  }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.state.authToken) {
      const headers = {
        'Authorization': 'Bearer ' + this.state.authToken
      };
      httpRequest = httpRequest.clone({
        setHeaders: headers
      });
    }
    return next.handle(httpRequest).pipe(catchError(error => {
      console.log('ERROR', error);
      /*if (error.status === 401) {
        console.log('401');
      }*/
      return throwError(error);
    }));
  }
}

