import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from "@angular/router";
import {inject} from "@angular/core";
import {StateService} from "./core/state.service";
import {environment} from "../environments/environment";
import {HttpClient} from "@angular/common/http";
import {CommunicationService} from "./core/communication.service";
import {ThemeService} from "./core/theme.service";

export const consentGuard: CanActivateFn = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {

  const stateService = inject(StateService);
  const communicationService = inject(CommunicationService);
  const httpClient = inject(HttpClient);
  const themeService = inject(ThemeService);
  let router = inject(Router);

  if (stateService.authenticated) {
    return true;
  }

  let queryParamUserId = route.queryParamMap.get('userId');
  queryParamUserId = queryParamUserId === 'null' || queryParamUserId === '0' ? null : queryParamUserId;

  stateService.userId = stateService.userId || queryParamUserId || ('test-' + new Date().toISOString().split('T')[0]);
  stateService.tenantId = stateService.tenantId || route.queryParamMap.get('clientId') || environment.tenantId;
  stateService.testMode = route.queryParamMap.get('testMode') === 'true' || !environment.production;

  communicationService.logToApp('userId: ' + stateService.userId);
  communicationService.logToApp('tenantId: ' + stateService.tenantId);
  communicationService.logToApp('testMode: ' + stateService.testMode);

  if (!stateService.authenticated) {
    const authRes = await httpClient.post<{ data: string }>(environment.apiUrl + '/Auth/authenticate',
      {username: environment.apiUser, password: environment.apiPass}
    ).toPromise();
    console.log('AUTH', authRes);
    stateService.authToken = authRes!.data;
    communicationService.logToApp('token fetched successfully');
    stateService.authenticated = true;
  }

  let punterRes = await httpClient.get<{ data: any }>(environment.apiUrl + '/customer/punter/'
    + stateService.tenantId + '/' + stateService.userId).toPromise();

  if (punterRes!.data == null) {
    console.log('creating punter');
    punterRes = await httpClient.post<{ data: any }>(environment.apiUrl + '/customer/punter/' + stateService.tenantId, {
      punterId: stateService.userId,
      language: stateService.language
    }).toPromise();
  } else {
    stateService.language = punterRes?.data?.language || 'it';
  }


  themeService.injectTheme();
  document.querySelector('#app-loading-spinner')?.remove();

  stateService.user = punterRes?.data!;
  console.log('punter', punterRes);
  // http://localhost:4200?clientId=1&userId=2&testMode=true
  if (stateService?.user?.termsAccepted == null || stateService?.user?.termsAccepted == "false") {
    return router.navigate(['/consent']);
  }
  return true;
};
